import React from 'react'
import './index.css'
import Constants from '../../../Constants';
import FooterLocation from '../../../types/elements/FooterLocation';

import FooterLogo from '../../../assets/images/elements/footer/footer-logo.png'
import SocialIcons from '../Header/NavBar/SocialIcons';
import NavigationItem from '../../../types/elements/NavigationItem';
import {Link} from 'react-router-dom';
import NewsletterBanner from './NewsletterBanner';
import Routes from '../../../Routes';

const locations: FooterLocation[] = [
    {
        title: 'KOMEDIS Freiberg am Neckar',
        contact: '<a href="tel:+4971411411900">07141 | 141 190 0</a><br/><a href="mailto:info@komedis.de">info@komedis.de</a>'
    },
    {
        title: 'KOMEDIS München',
        contact: '<a href="tel:+4971411411925">07141 | 141 19 25</a><br/><a href="mailto:bayern@komedis.de">bayern@komedis.de</a>'
    },
    {
        title: 'KOMEDIS Berlin',
        contact: '<a href="tel:+4930213005080">030 | 213 005 080</a><br/><a href="mailto:berlin@komedis.de">berlin@komedis.de</a>'
    },
    {
        title: 'KOMEDIS Frankenberg',
        contact: '<a href="tel:+496451742360">06451 | 742 360</a><br/><a href="mailto:hessen@komedis.de">hessen@komedis.de</a>'
    }/* ,
    {
        title: 'KOMEDIS Stadtname',
        contact: '<a href="tel:+49123456789">0123 | 456 789</a><br/><a href="mailto:info@komedis.de">info@komedis.de</a>'
    } */
];

const Footer = (props: {
    width: number
}) => {

    const {width} = props;

    const renderFooterLinkItem = (linkItem: NavigationItem, index: number) => {
        return (
            <Link
                key={'footer_link_item_' + index}
                to={linkItem.url ?? '/' + linkItem.route!.path}
                className="footer-link-item"
                style={linkItem.font ? {
                    fontSize: 20,
                    fontFamily: linkItem.font
                } : {}}
            >
                {linkItem.title}
            </Link>
        )
    }

    const renderLocationItem = (location: FooterLocation, index: number) => {
        return (
            <div key={'location_item_' + index} className="location-item">
                <p className="location-item-title">{location.title}</p>
                <p className="location-item-contact"
                   dangerouslySetInnerHTML={{__html: location.contact}}/>
                <div className="line"/>
            </div>
        )
    }

    return (
        <div id="footerContainer">
            <NewsletterBanner/>
            <footer id="footer">
                <div id="mainFooterWrapper">
                    <div id="mainFooter">
                        <div id="mainFooterTop">
                            <img id="footerLogo" src={FooterLogo} alt="KOMEDIS footer logo"/>
                            {width <= Constants.LayoutBreakpoints.desktop ? (
                                <SocialIcons color={true}/>
                            ) : null}
                            {width >= Constants.LayoutBreakpoints.tablet ? (
                                <Link
                                    id="applyButton"
                                    to="/kontakt"
                                >
                                    Initiativ-Bewerbung
                                </Link>
                            ) : null}
                        </div>
                        <div id="footerMiddleContainer">
                            <div id="footerLinksContainer">
                                <p id="footerLinksTitle">Links</p>
                                <div id="footerLinks">
                                    {renderFooterLinkItem({
                                        title: 'Home',
                                        route: {
                                            path: '',
                                            element: <></>
                                        }
                                    }, -1)}
                                    {Routes.NavigationItems.map(renderFooterLinkItem)}
                                </div>
                            </div>
                            {width >= Constants.LayoutBreakpoints.desktop ? (
                                <div id="locationsContainer">
                                    <p id="locationsTitle">Unsere Niederlassungen</p>
                                    <div id="locationItemsContainer">
                                        {locations.map(renderLocationItem)}
                                        <div className="location-item" style={{
                                            display: 'flex',
                                            flexDirection: 'column-reverse',
                                            marginBottom: '0.5rem'
                                        }}>
                                            <SocialIcons color={true}/>
                                        </div>
                                    </div>
                                </div>
                            ) : null}
                        </div>
                    </div>
                    {width < Constants.LayoutBreakpoints.tablet ? (
                        <Link
                            id="applyButton"
                            to="/kontakt"
                        >
                            Initiativ-Bewerbung
                        </Link>
                    ) : null}
                    {width < Constants.LayoutBreakpoints.desktop ? (
                        <div id="locationsContainer">
                            <p id="locationsTitle">Unsere Niederlassungen</p>
                            <div id="locationItemsContainer">
                                {locations.map(renderLocationItem)}
                            </div>
                        </div>
                    ) : null}
                </div>
                <div className="line" id="footerBottomLine"/>
                <div id="footerBottom">
                    <p id="legalLinks">
                        <a href="https://jobs.komedis.de/impressum/">Impressum</a>
                        <span>|</span>
                        <a href="https://jobs.komedis.de/datenschutzerklaerung/">Datenschutzerklärung</a>
                    </p>
                    <p id="copyright">
                        &copy; 2024 {Constants.COMPANY_NAME}. Alle Rechte vorbehalten.
                    </p>
                </div>
            </footer>
        </div>
    )
}

export default Footer
